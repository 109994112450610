<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        <v-img style="max-width: 500px" src="/img/assignments/cpp_hersheys.png" />
      </p>

      <p class="mb-2">How many kisses (pieces) are there in the container?</p>
      <calculation-input
        v-model="inputs.input1"
        class="mb-4"
        append-text="$\text{kisses}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        How many Calories are there in
        <stemble-latex content="$1$" />
        kiss?
      </p>
      <calculation-input
        v-model="inputs.input2"
        class="mb-4"
        append-text="$\text{Calories}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        How many
        <stemble-latex content="$\text{g}$" />
        of iron is equivalent to
        <stemble-latex content="$100\%$" />
        DV?
      </p>
      <calculation-input
        v-model="inputs.input3"
        class="mb-4"
        append-text="$\text{mg}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        What is the
        <stemble-latex content="$\%$" />
        DV of
        <stemble-latex content="$0.733\,\text{g}$" />
        of potassium?
      </p>
      <calculation-input
        v-model="inputs.input4"
        class="mb-4"
        append-text="$\%\,\text{DV}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        What is the total weight of kisses in the container in
        <stemble-latex content="$\text{kg?}$" />
      </p>
      <calculation-input
        v-model="inputs.input5"
        class="mb-4"
        append-text="$\text{kg}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        What
        <stemble-latex content="$\%$" />
        DV of sugar is consumed by eating
        <stemble-latex content="$4$ " />
        kisses?
      </p>
      <calculation-input
        v-model="inputs.input6"
        class="mb-4"
        append-text="$\%\,\text{DV}$"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import CalculationInput from '../inputs/CalculationInput';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'ChemCPP1210Chocolate',
  components: {
    CalculationInput,
    StembleLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      inputs: {
        input1: null,
        input2: null,
        input3: null,
        input4: null,
        input5: null,
        input6: null,
      },
    };
  },
};
</script>
